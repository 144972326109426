import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import $ from 'jquery';
import { Container } from 'react-bootstrap'
import { toast } from "react-toastify";
import { ENV } from '../config/config';
import VideoJS from './Video'
import videojs from 'video.js';
import './player.css'
import previewImage from '../assets/images/preview-coming-soon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

let metadata = [];
let videoTitle = '';
let subtitleTracksList = [];
let offsetX = 0;
let offsetY = 0;
let renderedWidth = 0;
let renderedHeight = 0;
let mouseIdDown = false;
let intentionalCheck = false
var objects = {
    'person': '#DFFF00',
    'bicycle': '#C0C0C0',
    'car': '#808080',
    'motorcycle': '#000000',
    'airplane': '#FF0000',
    'bus': '#800000',
    'train': '#FFFF00',
    'truck': '#808000',
    'boat': '#00FF00',
    'traffic light': '#008000',
    'fire hydrant': '#00FFFF',
    'stop sign': '#008080',
    'parking meter': '#0000FF',
    'bench': '#000080',
    'bird': '#FF00FF',
    'cat': '#800080',
    'dog': '#DFFF00',
    'horse': '#FFBF00',
    'sheep': '#FF7F50',
    'cow': '#DE3163',
    'elephant': '#9FE2BF',
    'bear': '#40E0D0',
    'zebra': '#6495ED',
    'giraffe': '#CCCCFF',
    'backpack': '#F0FFFF',
    'umbrella': '#89CFF0',
    'handbag': '#7393B3',
    'tie': '#088F8F',
    'suitcase': '#0096FF',
    'frisbee': '#0047AB',
    'skis': '#5F9EA0',
    'snowboard': '#6495ED',
    'sports ball': '#00FFFF',
    'kite': '#00008B',
    'baseball bat': '#6F8FAF',
    'baseball glove': '#1434A4',
    'skateboard': '#7DF9FF',
    'surfboard': '',
    'tennis racket': '#6082B6',
    'bottle': '#00A36C',
    'wine glass': '#3F00FF',
    'cup': '#5D3FD3',
    'fork': '#ADD8E6',
    'knife': '#191970',
    'spoon': '#1F51FF',
    'bowl': '#A7C7E7',
    'banana': '#CCCCFF',
    'apple': '#96DED1',
    'sandwich': '#4169E1',
    'orange': '#0F52BA',
    'broccoli': '#9FE2BF',
    'carrot': '#87CEEB',
    'hot dog': '#4682B4',
    'pizza': '#008080',
    'donut': '#40E0D0',
    'cake': '#0437F2',
    'chair': '#40B5AD',
    'couch': '#0818A8',
    'potted plant': '#EADDCA',
    'bed': '#E1C16E',
    'dining table': '#CD7F32',
    'toilet': '#DAA06D',
    'tv': '#800020',
    'laptop': '#E97451',
    'mouse': '#D27D2D',
    'remote': '#6F4E37',
    'keyboard': '#B87333',
    'cell phone': '#8B0000',
    'microwave': '#988558',
    'oven': '#C2B280',
    'toaster': '#C19A6B',
    'sink': '#E5AA70',
    'refrigerator': '#966919',
    'book': '#F0E68C',
    'clock': '#C04000',
    'vase': '#967969',
    'scissors': '#CC7722',
    'teddy bear': '#808000',
    'hair drier': '#4A0404',
    'toothbrush': '#A95C68'
}

const Player = () => {
    const { videoSlug } = useParams()
    const playerRef = useRef(null);
    const [clickedObject, setObject] = useState()
    const [openModal, setModal] = useState({})
    const [addCustomClass, setAddCustomClass] = useState(true);
    const [videoOptions, setVideoOptions] = useState({
        autoplay: true,
        muted: true,
        controls: true,
        loop: true,
        responsive: true,
        metadata: true,
        sources: [{
            src: 'https://static.vecteezy.com/system/resources/previews/004/844/745/mp4/icon-loading-10-circle-gradient-angle-loop-out-black-background-gradient-animation-for-game-animation-and-others-free-video.mp4', //`${ENV.streamingUrl}${1684479039317}/output.m3u8`,
            type: 'video/mp4'
        }],
        tracks: []
    })

    const getVideo = (videoSlug) => {
        let url = `${ENV.apiUrl}/videos/get/${videoSlug}`
        fetch(url, {
            method: "GET",
            headers: {
                'Authorization': ENV.authorization,
                'x-auth-token': ENV.xAuthToken
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    if (data?.video?.subtitles?.length) {
                        let subtitleTracks = data.video.subtitles.map((item) => {
                            const newObj = {};

                            newObj.src = item.cloudUrl;
                            newObj.kind = 'captions';
                            newObj.srclang = item.language?.code || '';
                            newObj.label = item.language?.label;

                            return newObj;
                        })
                        subtitleTracksList = subtitleTracks
                    }
                    getVideoMetadata(videoSlug)
                }
                else
                    toast.error(data.message)
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    const { data } = error.response;
                    if (data.message)
                        toast.error(data.message)
                }
            })
    }

    const getVideoMetadata = (slug) => {
        let url = `${ENV.apiUrl}/videos/get/metadata/${slug}`
        fetch(url, {
            method: "GET",
            headers: {
                'Authorization': ENV.authorization,
                'x-auth-token': ENV.xAuthToken
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    metadata = data.metadata["meta-data"];
                    videoTitle = data.metadata['video-title'].split(".")[0]
                    setVideoOptions({
                        muted: false,
                        sources: [{
                            src: `${ENV.streamingUrl}${videoTitle}/output.m3u8`,
                            type: 'application/x-mpegURL'
                        }],
                        tracks: subtitleTracksList
                    })
                    setAddCustomClass(false)
                }
                else
                    toast.error(data.message)
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    const { data } = error.response;
                    if (data.message)
                        toast.error(data.message)
                }
            })
    }

    useEffect(() => {
        if (videoSlug) {
            getVideo(videoSlug)
        }
    }, [videoSlug])

    useEffect(() => {
        if (videoOptions.sources[0]?.src) {
            //get player, video width, height
            setTimeout(() => {
                calculateDimensions(false)
            }, 1000)
        }
    }, [videoOptions])

    const calculateDimensions = async (fullScreenToggle = false) => {
        let playerWidth = ''
        let playerHeight = ''

        if (fullScreenToggle) {
            playerWidth = window.screen.width;
            playerHeight = window.screen.height;
        } else {
            playerWidth = $("video-js").width();
            playerHeight = $("video-js").height();
        }

        const videoWidth = playerRef.current.videoWidth();
        const videoHeight = playerRef.current.videoHeight();
        renderedHeight = playerHeight

        let heightDifference = videoHeight - renderedHeight;

        if (heightDifference < 0) heightDifference *= -1;
        let percentChangeInHeight = (heightDifference / videoHeight) * 100;
        if (renderedHeight > videoHeight) {
            renderedWidth = videoWidth + (percentChangeInHeight * videoWidth) / 100;
        }
        else {
            renderedWidth = videoWidth - (percentChangeInHeight * videoWidth) / 100;
        }

        let verticalBlanckSpaceEachSide = (playerWidth - renderedWidth) / 2;
        offsetX = verticalBlanckSpaceEachSide;
        offsetY = 0;
    }

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting', player.tech());
            $(".detected-elements").remove();
            player.one('canplay', () => {
                videojs.log('video can play', player.tech());
                player.play();

            });
        });

        player.on('play', () => {
            videojs.log('Video is playing');
            $(".detected-elements").remove();
        });

        player.on('pause', () => {
            videojs.log('Video is pause');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });

        player.on('fullscreenchange', function (e) {
            $(".detected-elements").remove();
            if (player.isFullscreen()) {
                calculateDimensions(player.isFullscreen())
            } else {
                calculateDimensions(false)
            }
        })
    };

    useEffect(() => {
        $(document).on("click", "video-js", function (e) {
            var video = document.getElementsByTagName("video");
            if (video[0].paused) {
                intentionalCheck = true
            }
            else {
                if (playerRef.current.play()) {
                    intentionalCheck = false
                }
            }
        })
        $(document).on("mouseup", function () {
            mouseIdDown = false;
        });
        $(document).on("mousemove", "video-js", function (e) {
            if (playerRef.current.seeking() && !mouseIdDown) {
                mouseIdDown = true;
            }
            if (!mouseIdDown) {
                pause(e);
            }
            if (mouseIdDown) {
                mouseIdDown = false;
            }
        })
        $(document).on("mouseleave", "video-js", function (e) {
            if (!intentionalCheck) {
                play();
            }
        })
    }, [])

    const play = () => {
        $(".detected-elements").remove();
        playerRef.current.play()
    }

    const findIndex = async (milliseconds, metadata) => {
        let nearestIndex = -1;
        let smallestDifference = Number.MAX_SAFE_INTEGER;

        // Check if the input index exists in the indexedArray
        if (metadata.hasOwnProperty(milliseconds)) {
            nearestIndex = milliseconds;
        } else {
            // Find the nearest index in the indexedArray
            for (const [key, value] of Object.entries(metadata)) {
                const currentDifference = Math.abs(parseInt(key) - milliseconds);
                if (currentDifference < smallestDifference) {
                    smallestDifference = currentDifference;
                    nearestIndex = parseInt(key);
                }
            }
        }

        return nearestIndex;
    }

    const repositionDetailsPopup = (x, y, elemWidth, metadata) => {
        const parentWidth = $("#custom-player").width()
        let startX = 0;
        if (x <= (parentWidth / 2))
            startX = (x + elemWidth + 5)
        else {
            const contianerWidth = 250
            startX = (x - contianerWidth - 5)
        }
        setObject({ ...metadata, startX, startY: y });
    }

    useEffect(() => {
        setModal(clickedObject ? true : false)
        let elem = document.getElementById('clicked-object-details')
        if (elem)
            elem.style.display = 'block'
    }, [clickedObject])


    const pause = async (e) => {
        $(".detected-elements").remove();
        await calculateDimensions(false);
        if (mouseIdDown) {
            return;
        }
        if ($(e.target).hasClass('vjs-control-bar') || $(e.target).parents('.vjs-control-bar').length) {
            e.preventDefault()
            return false;
        }
        playerRef.current.pause()

        let milliseconds = Math.floor(playerRef.current.currentTime() * 1000)

        if (milliseconds === 0) {
            return false
        }

        let nearestIndex = await findIndex(milliseconds, metadata)

        if (metadata[nearestIndex.toString()]?.objects.length) {

            for (let i = 0; i < metadata[nearestIndex.toString()].objects.length; i++) {

                if (metadata[nearestIndex.toString()].objects[i].la !== "unknown") {

                    let x = metadata[nearestIndex.toString()].objects[i].x;
                    let y = metadata[nearestIndex.toString()].objects[i].y;
                    let startX = (renderedWidth * x) + offsetX;
                    let startY = (renderedHeight * y) + offsetY;
                    let w = metadata[nearestIndex.toString()].objects[i].w;
                    let h = metadata[nearestIndex.toString()].objects[i].h;
                    let endX = (renderedWidth * w) + offsetX;
                    let endY = (renderedHeight * h) + offsetY;
                    let elemWidth = endX - startX;
                    let elemHeight = endY - startY;
                    let sumWidthHeight = (parseFloat(metadata[nearestIndex.toString()].objects[i].w) * 100) + (parseFloat(metadata[nearestIndex.toString()].objects[i].h) * 100)
                    let zIndexOfElem = Math.round(200 - sumWidthHeight)

                    let color = objects[metadata[nearestIndex.toString()].objects[i].la] ? objects[metadata[nearestIndex.toString()].objects[i].la] : "red";

                    let detectedElements = document.createElement('div')

                    // detectedElements.classList.add("detected-elements");
                    detectedElements.id = metadata[nearestIndex.toString()].objects[i].id
                    detectedElements.style.left = startX + "px";
                    detectedElements.style.top = startY + "px";
                    detectedElements.style.width = elemWidth + "px";
                    detectedElements.style.height = elemHeight + "px";
                    detectedElements.style.position = "absolute";
                    detectedElements.style.color = "hsl(0deg 0% 0%)";
                    detectedElements.style.outline = `${color} solid 2px`;
                    detectedElements.style.opacity = 0
                    detectedElements.style.zIndex = zIndexOfElem

                    // Create span element.
                    var span = document.createElement('span');
                    span.innerHTML = metadata[nearestIndex.toString()].objects[i].la;
                    span.style.backgroundColor = color;
                    span.style.fontWeight = "bold";
                    span.style.fontSize = "14px";
                    span.style.color = "white";
                    detectedElements.classList.add("detected-elements");
                    detectedElements.onmousemove = function (e) {
                        pause(e);
                    };

                    // Append the span element to the Main DIV.
                    detectedElements.appendChild(span)

                    let playerWrapper = document.getElementById("vjs_video_3");
                    playerWrapper.appendChild(detectedElements);

                    // add onclick listener to objects
                    detectedElements.onclick = () => {
                        repositionDetailsPopup(startX, startY, elemWidth, metadata[nearestIndex.toString()].objects[i])
                    }

                    detectedElements.onmouseenter = () => {
                        detectedElements.style.opacity = 1
                    };

                    detectedElements.onmouseleave = () => {
                        detectedElements.style.opacity = 0
                    };

                }

            }
        }
    }

    const onCloseHandler = () => {
        setModal(false)
        setObject(null)
    }


    return (
        <div className='stanalone-v-player-container v-player-container dashboardHeader'>
            <Container fluid className='p-0 h-100'>
                <div className='video-detail-section h-100 d-block'>
                    <div className='video-area'>
                        <div id="custom-player" className={`v-custom-player ${addCustomClass ? "custom-controls" : ""}`}>
                            <VideoJS options={videoOptions} onReady={(player) => handlePlayerReady(player)} />
                            {
                                clickedObject &&
                                <div id="clicked-object-details" className={`clicked-object-detail ${openModal && clickedObject ? "d-block" : ""}`} style={{ left: 0, top: 0, maxHeight: '314px', overflowY: 'auto'}}>
                                    <div className=' d-flex justify-content-end'>
                                        <span className="icon-cross cursor-pointer" onClick={() => onCloseHandler()}><FontAwesomeIcon icon={faTimes} /></span>
                                    </div>
                                    <strong className="object-name d-block text-center">{clickedObject.la}</strong>
                                    <h3 className="text-start">Description</h3>
                                    <p className="text-start modal-description">{clickedObject.ds || 'Description not available'}</p>
                                    <Link to='/' className='show-text-btns'>Show More</Link>                                  
                                    <div className="img-holder mb-2">
                                        <img className="img-fluid" src={clickedObject?.im || previewImage} alt="Preview" />
                                    </div>
                                    {/* <ul className="rating list-unstyled d-flex mb-3">
                                        <li className="rated"><FontAwesomeIcon icon={faStar} /></li>
                                        <li className="rated"><FontAwesomeIcon icon={faStar} /></li>
                                        <li className="rated"><FontAwesomeIcon icon={faStar} /></li>
                                        <li className="rated"><FontAwesomeIcon icon={faStar} /></li>
                                        <li className="rated"><FontAwesomeIcon icon={faStar} /></li>
                                    </ul> */}
                                    <div className="d-flex">
                                        {clickedObject.lk ?
                                            <a className="link-to d-inline-block align-top" target='_blank' href={clickedObject.lk} rel="noreferrer">Check Now</a>
                                            :
                                            <span className="link-not-found">Link Not Available</span>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </Container>
        </div>
    )
}

export default Player