import { Route, Routes } from "react-router-dom";
import Player from "./Player/Player";


function App() {
  return (
    <Routes>
      <Route
        exact={true}
        path={'/:videoSlug'}
        element={<Player />}>
      </Route>
    </Routes>
  );
}

export default App;
