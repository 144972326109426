import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { toast, ToastContainer, Slide } from 'react-toastify';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ToastContainer
      position={toast.POSITION.TOP_RIGHT}
      pauseOnHover={true}
      newestOnTop={true}
      autoClose={3000}
      toastStyle={{ backgroundColor: "#222430", color: '#fff', boxShadow: '#fd6f21 0px 0px 14px 0px', fontSize: '15px' }}
      transition={Slide}
    />
    <BrowserRouter>
      <App />
    </BrowserRouter>

  </>
);

reportWebVitals();
